import { z } from "zod";

import { c } from "@/contract";
import {
  CompaniesQuery,
  CompanyCreate,
  CompanyExtended,
  CompanyUpdate,
  CompanyReference,
  CompanySuspend,
  CompanyAddDevice,
} from "@/admin/schemas/dtos";
import { PaginationMeta } from "@/models";
import { CustomError } from "@/models/custom_error";

export const companyContract = c.router({
  getCompanies: {
    method: "GET",
    path: "/companies",
    query: CompaniesQuery,
    responses: {
      200: z.object({
        data: CompanyExtended.array(),
        meta: PaginationMeta,
      }),
      400: CustomError,
    },
    summary: "search companies with filter",
  },
  getCompany: {
    method: "GET",
    path: "/companies/:id",
    responses: {
      200: CompanyExtended,
      400: CustomError,
    },
    summary: "get company detail by id",
  },
  getCompanyReferences: {
    method: "GET",
    path: "/companies_references",
    responses: {
      200: CompanyReference.array(),
      400: CustomError,
    },
    summary: "get company detail by id",
  },
  updateCompany: {
    method: "PATCH",
    path: "/companies/:id",
    body: CompanyUpdate,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "update company",
  },
  createCompany: {
    method: "POST",
    path: "/companies",
    body: CompanyCreate,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "create company",
  },
  deleteCompany: {
    method: "DELETE",
    path: "/companies/:id",
    body: z.object({}),
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "set company inactive",
  },
  suspendCompany: {
    method: "PATCH",
    path: "/companies/:id/suspend",
    body: CompanySuspend,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "set company inactive",
  },
  addDeviceCompany: {
    method: "PATCH",
    path: "/companies/:id/device",
    body: CompanyAddDevice,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "set company inactive",
  },
});
