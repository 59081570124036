import { z } from "zod";

import { paginationQuery } from "@/models";
import { BatteryModel } from "@/models/battery";
import { DeviceModel } from "@/models/device";

export const DeviceExtended = DeviceModel.extend({
  battery: BatteryModel.nullable(),
});

export type DeviceExtended = z.infer<typeof DeviceExtended>;

export const DeviceReference = DeviceModel.pick({
  deviceId: true,
  name: true,
  serialId: true,
});
export type DeviceReference = z.infer<typeof DeviceReference>;

export const DeviceCreate = DeviceModel.omit({
  deviceId: true,
  createdAt: true,
  updatedAt: true,
  updatedBy: true,
  createdBy: true,
}).extend({
  simId: z.string().optional(),
  ipAddress: z.string().optional(),
  macId: z.string().optional(),
  phoneNumber: z.string().optional(),
});
export type DeviceCreate = z.infer<typeof DeviceCreate>;

export const DeviceUpdate = DeviceModel.pick({
  name: true,
  serialId: true,
  macId: true,
  ipAddress: true,
  simId: true,
  phoneNumber: true,
});
export type DeviceUpdate = z.infer<typeof DeviceUpdate>;

export const DevicesQuery = z
  .object({
    keywords: z.string().array(),
    phoneNumber: z.string(),
    ipAddress: z.string(),
    serialId: z.string().array(),
    isUse: z.boolean(),
  })
  .merge(paginationQuery(z.enum(["updatedAt"])))
  .partial()
  .optional();

export type DevicesQuery = z.infer<typeof DevicesQuery>;

export const DeviceAddBattery = z.object({
  batteryId: z.number()
});

export type DeviceAddBattery = z.infer<typeof DeviceAddBattery>;

