import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdAccountCircle,
  MdBusinessCenter,
  MdDevices,
  MdBatteryChargingFull,
  MdFolder,
  MdSettings,
  MdBatteryCharging80,
  MdPieChart,
  MdInsertChart,
} from 'react-icons/md';

const navRoutes = [
  {
    name: 'nav.home',
    path: '/home',
    icon: <MdHome className="text-inherit h-5 w-5" />,
  },
  {
    name: 'nav.company',
    path: '/company',
    icon: <MdBusinessCenter className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'nav.company_list',
        path: '/company/list',
      },
      {
        name: 'nav.company_new',
        path: '/company/new',
      },
    ],
  },

  {
    name: 'nav.customer',
    path: '/customer',
    icon: <MdAccountCircle className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'nav.customer_list',
        path: '/customer/list',
      },
      {
        name: 'nav.customer_new',
        path: '/customer/new',
      },
    ],
  },
  {
    name: 'nav.device',
    path: '/device',
    icon: <MdDevices className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'nav.device_list',
        path: '/device/list',
      },
      {
        name: 'nav.device_new',
        path: '/device/new',
      },
    ],
  },
  {
    name: 'nav.battery',
    path: '/battery',
    icon: <MdBatteryCharging80 className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'nav.battery_list',
        path: '/battery/list',
      },
      {
        name: 'nav.battery_new',
        path: '/battery/new',
      },
    ],
  },
  {
    name: 'nav.report',
    path: '/reports',
    icon: <MdInsertChart className="text-inherit h-5 w-5" />,
    collapse: true,

    items: [
      {
        name: 'nav.transaction_report',
        path: '/reports/transaction',
      },
      {
        name: 'nav.worked_report',

        path: '/reports/worked',
      },
      {
        name: 'nav.other_report',

        path: '/reports/other',
      },
    ],
  },
  {
    name: 'nav.settings',
    path: '/settings',
    icon: <MdSettings className="text-inherit h-5 w-5" />,
    collapse: true,

    items: [
      {
        name: 'nav.staffs',
        path: '/settings/staff',
      },
      {
        name: 'nav.logout',
        path: '/logout',
      },
    ],
  },
];
export default navRoutes;
