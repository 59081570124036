import {
  account_status,
  admin_user_role
} from 'database';
import { z } from "zod";

export const EnumReference = <T>(schema: z.ZodType<T>) =>
  z.object({
    code: schema,
    name: z.string(),
  });
export type EnumReference = z.infer<ReturnType<typeof EnumReference>>;

export const AccountStatusEnum = z.enum(account_status.enumValues);
export type AccountStatusEnum = z.infer<typeof AccountStatusEnum>;

export const UserRoleEnum = z.enum(admin_user_role.enumValues);
export type UserRoleEnum = z.infer<typeof UserRoleEnum>;
