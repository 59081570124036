/* eslint-disable */

import { HiX } from 'react-icons/hi';
import Links from './components/SidebarLinks';

import SidebarCard from 'components/sidebar/components/SidebarCard';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import logoGreen from 'assets/img/layout/alsaalaa-logo-green.png';
import logoWhite from 'assets/img/layout/alsaalaa-logo-white.png';

import Card from 'components/card';
import navRoutes from 'routes/navRoutes';
import { useAuth } from 'providers/auth';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function SidebarHorizon(props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  variant?: string;
  [x: string]: any;
}) {
  const { open, onClose, variant, mini, hovered, setHovered } = props;

  const { me } = useAuth();
  const [darkmode, setDarkmode] = useState(
    document.body.classList.contains('dark')
  );

  return (
    <div
      className={`sm:none ${
        mini === false
          ? 'w-[285px]'
          : mini === true && hovered === true
          ? 'w-[285px]'
          : 'w-[285px] xl:!w-[120px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card
        extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div className={`ml-[22px]  mt-[44px] flex items-center `}>
                <div
                  className={`mt-1 ml-1 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'hidden'
                  }`}
                >
                  {!darkmode ? (
                    <img src={logoGreen} alt="Asaala" className={`h-[60px]`} />
                  ) : (
                    <img src={logoWhite} alt="Asaala" className={`h-[60px]`} />
                  )}
                </div>
                <div
                  className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'hidden'
                      : mini === true && hovered === true
                      ? 'hidden'
                      : 'block'
                  }`}
                >
                  A
                </div>
              </div>
              <div className="mt-[58px] mb-7 h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul>
                <Links mini={mini} hovered={hovered} navRoutes={navRoutes} />
              </ul>
            </div>
            {/* Free Horizon Card */}
            <div className="mt-[28px] mb-[30px]">
              {/* Sidebar profile info */}
              <Link
                to="/profile"
                className="mt-5 ml-[22px] flex items-center justify-start gap-2"
              >
                {me.avatar ? (
                  <div className="h-12 w-12 rounded-full bg-blue-200">
                    <img
                      src={me.avatar}
                      className="rounded-full"
                      alt="avatar"
                    />
                  </div>
                ) : (
                  <div className="relative w-12 h-12 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                    <svg
                      className="absolute w-14 h-14 text-gray-400 -left-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                )}

                <div
                  className={`ml-1 ${
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'block xl:hidden'
                  }`}
                >
                  <h4 className="text-base font-bold text-navy-700 dark:text-white">
                    {me.firstname ||
                      me.middleName ||
                      me.email ||
                      me.lastName ||
                      'Unnamed'}
                  </h4>
                  <p className="text-sm font-medium text-gray-600">
                    {me.company?.name || 'No company'}
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;
