import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'mn',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      mn: {
        translation: {
          nav: {
            home: 'Нүүр',
            company: 'Байгууллага',
            company_list: 'Байгууллага',
            company_new: 'Байгууллага үүсгэх',
            customer: 'Үйлчлүүлэгч',
            device: 'Төхөөрөмж',
            battery: 'Солих баттерей',
            report: 'Тайлан',
            transaction_report: 'Гүйлгээний тайлан',
            worked_report: 'Ажилласан тайлан',
            other_report: 'Бусад тайлан',
            settings: 'Тохиргоо',
            staffs: 'Ажилтанууд',
            logout: 'Системээс гарах',
            profile: 'Профайл засах',
          },
          company: {
            name: 'Байгууллагын нэр',
            personInCharge: 'Удирдаж буй хүн',
            registerId: 'Бүртгэлийн дугаар',
            features: 'Features',
            phoneNumber: 'Утасны дугаар',
            homePage: 'Нүүр хуудас',
            country: 'Улс',
            prefecture: 'Муж',
            city: 'Хот',
            zipCode: 'Зип код',
            latitude: 'Өргөрөг',
            longitude: 'Уртраг',
            addressDetails: 'Хаягийн дэлгэрэнгүй мэдээлэл',
            createCompany: 'Байгууллага үүсгэх',
            updateCompany: 'Байгууллага засах',
          },
          device: {
            name: 'Device name',
            serialId: 'Serial Id',
            simId: 'Sim Id',
            ipAddress: 'Ip address',
            macId: 'Mac Id',
            phoneNumber: 'Phone number',
            isUse: 'Is use',
            keywords: 'Keywords',
            createDevice: 'Create device',
            updateDevice: 'Update device',
          },
          profile: {
            firstName: 'First name',
            lastName: 'Last name',
            middleName: 'Middle name',
            email: 'Email address',
            phoneNumber: 'Phone number',
            oldPassword: 'Old password',
            newPassword: 'New password',
            reNewPassword: 'New password Confirmation',
            savePassword: 'Change password',
            saveChanges: 'Save changes',
          },
          error: {
            default: 'Алдаа гарлаа.',
            fetch: 'Холбогдход алдаа гарлаа',
          },
          pages: 'Хуудас',
          auth: {
            signin: 'Нэвтрэх',
            signin_description: 'Э-мэйл нууц үгээ хийж нэвтрэн орно уу!',
            email: 'Э-мэйл',
            password: 'Нууц үг',
            forgot: 'Нууц үгээ мартсан уу?',
          },
        },
      },
      en: {
        translation: {
          nav: {
            home: 'Home',
            company: 'Company',
            company_list: 'Companies',
            company_new: 'New company',
            customer: 'Customer',
            customer_list: 'Customers',
            customer_new: 'New customer',
            device: 'Device',
            device_list: 'Devices',
            device_new: 'New device',
            battery: 'Battery',
            battery_list: 'Batteries',
            battery_new: 'New battery',
            report: 'Report',
            transaction_report: 'Transaction report',
            worked_report: 'Worked report',
            other_report: 'Other report',
            settings: 'Settings',
            staffs: 'Staffs',
            logout: 'Logout',
            profile: 'Profile settings',
          },
          company: {
            name: 'Company name',
            personInCharge: 'Person in charge',
            registerId: 'Register Id',
            features: 'Features',
            phoneNumber: 'Phone number',
            homePage: 'Home page',
            country: 'Country',
            prefecture: 'Prefecture',
            city: 'City',
            zipCode: 'Zip code',
            latitude: 'Latitude',
            longitude: 'Longitude',
            addressDetails: 'Address details',
            createCompany: 'Create company',
            updateCompany: 'Update company',
          },
          device: {
            name: 'Device name',
            serialId: 'Serial Id',
            simId: 'Sim Id',
            ipAddress: 'Ip address',
            macId: 'Mac Id',
            phoneNumber: 'Phone number',
            isUse: 'Is use',
            keywords: 'Keywords',
            createDevice: 'Create device',
            updateDevice: 'Update device',
          },
          profile: {
            firstName: 'First name',
            lastName: 'Last name',
            middleName: 'Middle name',
            email: 'Email address',
            phoneNumber: 'Phone number',
            oldPassword: 'Old password',
            newPassword: 'New password',
            reNewPassword: 'New password Confirmation',
            savePassword: 'Change password',
            saveChanges: 'Save changes',
          },
          error: {
            default: 'Something wrong.',
            fetch: 'Failed to fetch',
          },
          pages: 'Pages',
          auth: {
            signin: 'Sign In',
            signin_description: 'Enter your email and password to sign in!',
            email: 'Email',
            password: 'Password',
            forgot: 'Forgot Password?',
          },
        },
      },
    },
  });

export default i18n;
