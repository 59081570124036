import { initClient } from '@ts-rest/core';
import { adminContract } from 'api/admin';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const restAPI = initClient(adminContract, {
  baseUrl: process.env.REACT_APP_API_URL,
  baseHeaders: {
    ...(cookies.get('token')
      ? { Authorization: `Bearer ${cookies.get('token')}` }
      : {}),
  },
});
export default restAPI;
