import { z } from "zod";

import { AdminModel } from "@/models/admin";
import { CompanyModel } from "@/models/company";

export const AdminProfile = AdminModel.pick({
  firstName: true,
  email: true,
  adminId: true,
  lastName: true,
  middleName: true,
  phoneNumber: true,
}).extend({
  company: CompanyModel.pick({
    name: true,
  })
});

export type AdminProfile = z.infer<typeof AdminProfile>;

export const AdminProfileCreate = AdminModel.pick({
  firstName: true,
  lastName: true,
  middleName: true,
  phoneNumber: true,
});
export type AdminProfileCreate = z.infer<typeof AdminProfileCreate>;

export const AdminProfileUpdate = AdminModel.pick({
  firstName: true,
  lastName: true,
  middleName: true,
  phoneNumber: true,
});
export type AdminProfileUpdate = z.infer<typeof AdminProfileUpdate>;
