import { z } from "zod";

import {
  paginationQuery,
} from "@/models";
import { BatteryModel } from "@/models/battery";
import { DeviceBatteryModel, DeviceModel } from "@/models/device";

export const BatteryExtended = BatteryModel.pick({
  batteryId: true,
  name: true,
  serialId: true,
}).extend({
  deviceBattery: DeviceBatteryModel.nullable()
});

export type BatteryExtended = z.infer<typeof BatteryExtended>;

export const BatteryReference = BatteryModel.pick({
  batteryId: true,
  serialId: true,
  name: true,
});
export type BatteryReference = z.infer<typeof BatteryReference>;

export const BatteryCreate = BatteryModel.omit({
  batteryId: true,
  createdAt: true,
  updatedAt: true,
  updatedBy: true,
});
export type BatteryCreate = z.infer<typeof BatteryCreate>;

export const BatteryUpdate = BatteryModel.pick({
  name: true,
  serialId: true,
});
export type BatteryUpdate = z.infer<typeof BatteryUpdate>;

export const BatteriesQuery = z
  .object({
    keywords: z.string().array(),
    name: z.string(),
    deviceId: z.string().array(),
    isUse: z.string().or(z.boolean())
  })
  .merge(paginationQuery(z.enum(["updatedAt"])))
  .partial()
  .optional();

export type BatteriesQuery = z.infer<typeof BatteriesQuery>;
