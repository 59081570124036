import { z } from "zod";

import { c } from "@/contract";
import {
  ChangeEmailRequest,
  ChangePasswordRequest,
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
  RegisterRequest,
  VerifyEmailRequest,
} from "@/admin/schemas/dtos";
import { CustomError } from "@/models/custom_error";

export const authContract = c.router({
  register: {
    method: "POST",
    path: "/register",
    body: RegisterRequest,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  verifyAccount: {
    method: "POST",
    path: "/verify_account",
    body: VerifyEmailRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  login: {
    method: "POST",
    path: "/login",
    body: LoginRequest,
    responses: {
      200: LoginResponse,
      400: CustomError,
    },
    summary: "",
  },
  changeEmail: {
    method: "PATCH",
    path: "/change_email",
    body: ChangeEmailRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  verifyChangeEmail: {
    method: "POST",
    path: "/verify_change_email",
    body: VerifyEmailRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  changePassword: {
    method: "PATCH",
    path: "/change_password",
    body: ChangePasswordRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  forgotPassword: {
    method: "POST",
    path: "/forgot_password",
    body: ForgotPasswordRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  resetPassword: {
    method: "POST",
    path: "/reset_password",
    body: ResetPasswordRequest,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
});
