import { account_status } from "@/enums/account_status";
import { countries } from "@/references/countries";
import { relations } from "drizzle-orm";
import { pgTable, text, timestamp, serial, integer, boolean, uuid } from "drizzle-orm/pg-core";


export const users = pgTable("users", {
  id: serial("user_id").primaryKey(),
  loginName: text("login_name").notNull().unique(),
  email: text("email").notNull(),
  passwordHash: text("password_hash").notNull(),
  firstName: text("first_name"),
  middleName: text("middle_name"),
  lastName: text("last_name"),
  mobile: text("mobile"),
  isEmailVerified: boolean("is_email_verified").notNull().default(false),
  isMobileVerified: boolean("is_mobile_verified").notNull().default(false),
  status: account_status("status").notNull().default("pending_verify"),
  countryId: integer("country_id").references(() => countries.countryId),
  lastLoggedInAt: timestamp("last_logged_in_at"),
  loggedInCount: integer("logged_in_count").notNull().default(0),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const users_relations = relations(users, ({ one }) => ({
  country: one(countries, {
    fields: [users.countryId],
    references: [countries.countryId],
  }),
}));