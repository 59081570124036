import React, { createContext, useEffect, useState, useRef } from 'react';
import restAPI from 'restApi';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const AuthContext = createContext();

function AuthProvider(props) {
  const [isAuthenticated, setAuthenticate] = useState(false);
  const [me, setMe] = useState();
  const [loading, setLoading] = useState(true);

  const setAuth = async (data, token) => {
    if (data && token) {
      cookies.set('token', token, { path: '/' });
      setMe(data);
      setAuthenticate(true);
      // toast.success('Тавтай морилно уу');
    } else {
      // toast.success('Хэрэглэгч олдсонгүй');
    }
  };
  const removeAuth = () => {
    cookies.remove('token', { path: '/' });
    setMe('');
    setAuthenticate(false);
    setLoading(false);
  };

  useEffect(() => {
    // console.log("env ", process.env.REACT_APP_API_URL)
    const requestUser = async () => {
      if (cookies.get('token')) {
        try {
          const profile = await restAPI.profile.getProfile({
            headers: { Authorization: `Bearer ${cookies.get('token')}` },
          });
  
          if (profile?.status === 200 && profile?.body) {
         
            setMe(profile.body);
            setAuthenticate(true);
            setLoading(false);
          } else {
            removeAuth();
          }
        } catch (error) {
          removeAuth();
          console.log('error:', error.message);
        }
       
      } else {
        removeAuth();
      }
    };

    if (!isAuthenticated) {
      requestUser();
    } else {
      // removeAuth();
    }
    // eslint-disable-next-line
  }, []);

  if (loading)
    return (
      <div className="minvh-100 d-flex w-100 align-items-center justify-content-center">
        <div className="pos-rel h-100">
          loading...
          {/* <SpinLoader loading={true} /> */}
        </div>
      </div>
    );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        me,
        setMe,
        setAuth,
        removeAuth,
        loading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
