import { z } from "zod";

export const CustomError = z.object({
  code: z.string(),
  details: z.string().optional(),
  message: z.string().optional(),
});
export type CustomError = z.infer<typeof CustomError>;

export const isCustomError = (value: unknown): value is CustomError => {
  return CustomError.safeParse(value).success;
};
