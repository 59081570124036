import { relations } from "drizzle-orm";
import { integer, pgTable, serial, text } from "drizzle-orm/pg-core";

import { countries } from "./countries";
import { dictionary_entries } from "@/schemas/dictionary_entries";
import { cities } from "./cities";

export const prefectures = pgTable("prefectures", {
  prefectureId: serial("prefecture_id").primaryKey(),
  name: text("name").notNull(),
  code: text("code"),
  countryId: integer("country_id")
    .notNull()
    .references(() => countries.countryId),
  dictionaryId: integer("dictionary_id")
    .notNull()
    .references(() => dictionary_entries.dictionaryId),
});

export const prefectures_relations = relations(prefectures, ({ many, one }) => ({
  country: one(countries, {
    fields: [prefectures.countryId],
    references: [countries.countryId],
  }),
  cities: many(cities),
  dictionaries: many(dictionary_entries),
}));
