import { AdminModel } from "@/models/admin";
import { CompanyModel } from "@/models/company";
import { z } from "zod";

export const LoginRequest = z.object({
  email: z.string(),
  password: z.string(),
});
export type LoginRequest = z.infer<typeof LoginRequest>;

export const LoginResponse = z.object({
  accessToken: z.string(),
});
export type LoginResponse = z.infer<typeof LoginResponse>;

export const RegisterRequest = z.object({
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
});
export type RegisterRequest = z.infer<typeof RegisterRequest>;

export const VerifyEmailRequest = z.object({
  token: z.string(),
});
export type VerifyEmailRequest = z.infer<
  typeof VerifyEmailRequest
>;

export const ChangeEmailRequest = z.object({
  email: z.string(),
});
export type ChangeEmailRequest = z.infer<
  typeof ChangeEmailRequest
>;

export const ChangePasswordRequest = z.object({
  new_password: z.string(),
  old_password: z.string(),
});
export type ChangePasswordRequest = z.infer<
  typeof ChangePasswordRequest
>;

export const ForgotPasswordRequest = z.object({
  email: z.string(),
});
export type ForgotPasswordRequest = z.infer<
  typeof ForgotPasswordRequest
>;

export const ResetPasswordRequest = z.object({
  password: z.string(),
  token: z.string(),
});
export type ResetPasswordRequest = z.infer<
  typeof ResetPasswordRequest
>;

export const AdminAccount = AdminModel.extend({
  company: CompanyModel,
});

export type AdminAccount = z.infer<typeof AdminAccount>; 