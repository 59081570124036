import { z } from "zod";

import {
  AdminModel,
} from "@/models/admin";
import { CompanyModel } from "@/models/company";
import { AccountStatusEnum, UserRoleEnum } from "@/models/enum";
import { paginationQuery } from "@/models/pagination";

export const AdminCreate = AdminModel.pick({
  firstName: true,
  firstNameKana: true,
  middleName: true,
  middleNameKana: true,
  lastName: true,
  lastNameKana: true,
  phoneNumber: true,
}).extend({
  email: z.string().email(),
  role: UserRoleEnum,
  company: CompanyModel.optional()
});

export type AdminCreate = z.infer<typeof AdminCreate>;

export const AdminInvite = AdminCreate;

export type AdminInvite = z.infer<typeof AdminInvite>;

export const AdminAccountExtended = AdminModel.pick({
  adminId: true,
  email: true,
  isEmailVerified: true,
  lastLoggedInAt: true,
  loggedInCount: true,
})
  .extend({
    status: AccountStatusEnum.nullish(),
    company: CompanyModel.nullish(),
  })
  .merge(
    AdminModel.pick({
      firstName: true,
      lastName: true,
      middleName: true,
      phoneNumber: true,
    }),
  )
  .extend({
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    adminRole: UserRoleEnum.nullish(),
  });
export type AdminAccountExtended = z.infer<typeof AdminAccountExtended>;

export const AdminAccountQuery = z
  .object({
    name: z.string(),
    phoneNumber: z.string(),
    status: AccountStatusEnum.array(),
    role: UserRoleEnum.array(),
    keywords: z.string().array(),
  })
  .merge(paginationQuery(z.enum(["lastLoggedInAt", "createdAt", "updatedAt"])))
  .partial()
  .optional();
export type AdminAccountQuery = z.infer<typeof AdminAccountQuery>;

export const AdminAccountsStatusUpdate = z.object({
  status: AccountStatusEnum,
  ids: z.string().array(),
});

export type AdminAccountsStatusUpdate = z.infer<typeof AdminAccountsStatusUpdate>;
