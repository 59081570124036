import { z } from "zod";

import { c } from "@/contract";
import { DeviceAddBattery, DeviceCreate, DeviceExtended, DeviceReference, DeviceUpdate, DevicesQuery } from "@/admin/schemas/dtos";
import { PaginationMeta } from "@/models";
import { CustomError } from "@/models/custom_error";

export const deviceContract = c.router({
  getDevices: {
    method: "GET",
    path: "/devices",
    query: DevicesQuery,
    responses: {
      200: z.object({
        data: DeviceExtended.array(),
        meta: PaginationMeta,
      }),
      400: CustomError,
    },
    summary: "search devices with filter",
  },
  getDevice: {
    method: "GET",
    path: "/devices/:id",
    responses: {
      200: DeviceExtended,
      400: CustomError,
    },
    summary: "get device detail by id",
  },
  updateDevice: {
    method: "PATCH",
    path: "/devices/:id",
    body: DeviceUpdate,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "update device",
  },
  createDevice: {
    method: "POST",
    path: "/devices",
    body: DeviceCreate,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "create device",
  },
  getDeviceRef: {
    method: "GET",
    path: "/devices_ref",
    responses: {
      200: DeviceReference.array(),
      400: CustomError,
    },
    summary: "",
  },
  addBatteryDevice: {
    method: "PATCH",
    path: "/devices/:id/battery",
    body: DeviceAddBattery,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
});
