import React, { createContext, useEffect, useState, useRef } from 'react';
import { Toaster } from 'react-hot-toast';

export const ThemeContext = createContext();

function ThemeProvider(props) {
  // Create a new context
  const [themeApp, setThemeApp] = useState({
    '--background-100': '#FFFFFF',
    '--background-900': '#070f2e',
    '--shadow-100': 'rgba(112, 144, 176, 0.08)',
    '--color-50': '#EBFAF8',
    '--color-100': '#D7F4F2',
    '--color-200': '#1ac1b9',
    '--color-300': '#108b85',
    '--color-400': '#0b615d',
    '--color-500': '#063d3a',
    '--color-600': '#063d3a',
    '--color-700': '#063432',
    '--color-800': '#041f1e',
    '--color-900': '#0B2826',
    '--color-950': '#051413',
  });
  // brand color 063c3a
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let mode = localStorage.getItem('mz-theme');
    if (mode === 'dark') {
      document.body.classList.add('dark');
    }

    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    //eslint-disable-next-line
  }, [themeApp]);
  return (
    <ThemeContext.Provider
      value={{
        setMini: setMini,
        mini: mini,
        theme: themeApp,
        setTheme: setThemeApp,
      }}
    >
      {props.children}
      <Toaster position="top-center" reverseOrder={false} />
    </ThemeContext.Provider>
  );
}

const useCustomTheme = () => React.useContext(ThemeContext);

export { ThemeProvider, useCustomTheme };
