import { AuthProvider } from './auth';
import { ErrorProvider } from './error';
import { ReferencesProvider } from './references';
import { ThemeProvider } from './theme';

export function GlobalProviders({ children }) {
  return (
    <ErrorProvider>
      <ThemeProvider>
        <AuthProvider>{children}</AuthProvider>
      </ThemeProvider>
    </ErrorProvider>
  );
}
export function ProtectedProviders({ children }) {
  return <ReferencesProvider>{children}</ReferencesProvider>;
}
