import { useEffect, useState } from 'react';

const useReferencesFetch = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);

  const fetchData = async (url) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setApiData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setServerError(error);
        setIsLoading(false);
      });
  };

  return { isLoading, apiData, serverError, fetchData };
};
export default useReferencesFetch;
