import { relations } from "drizzle-orm";
import {
  pgTable,
  timestamp,
  serial,
  integer,
} from "drizzle-orm/pg-core";
import { admins } from "@/schemas/admins";
import { batteries } from "@/schemas/batteries";
import { devices } from ".";

export const device_batteries = pgTable("device_batteries", {
  deviceBatteryId: serial("id").primaryKey(),
  deviceId: integer("device_id").references(() => devices.deviceId),
  batteryId: integer("battery_id").references(() => batteries.batteryId),
  updatedBy: integer("updated_by")
    .references(() => admins.adminId),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const device_batteries_relations = relations(device_batteries, ({ one }) => ({
  device: one(devices, {
    fields: [device_batteries.deviceId],
    references: [devices.deviceId],
  }),
  battery: one(batteries, {
    fields: [device_batteries.batteryId],
    references: [batteries.batteryId],
  }),
}));