import { relations } from "drizzle-orm";
import { integer, pgTable, text, serial } from "drizzle-orm/pg-core";

import { prefectures } from "./prefectures";
import { dictionary_entries } from "@/schemas/dictionary_entries";

export const cities = pgTable("cities", {
  cityId: serial("city_id").primaryKey(),
  name: text("name").notNull(),
  prefectureId: integer("prefecture_id")
    .notNull()
    .references(() => prefectures.prefectureId),
  dictionaryId: integer("dictionary_id")
    .notNull().unique()
});

export const cities_relations = relations(cities, ({ many, one }) => ({
  prefecture: one(prefectures, {
    fields: [cities.prefectureId],
    references: [prefectures.prefectureId],
  }),
  dictionaries: many(dictionary_entries),
}));
