import { useError } from 'providers/error';
import { AiFillExclamationCircle } from 'react-icons/ai';
import SolidSubtleMultiAlert from 'views/admin/main/others/notifications/components/SolidSubtleMultiAlert';

export default function ServerError() {
  const { error, setError } = useError();
  if (!error) return <></>;

  return (
    <div className="mt-3">
      <SolidSubtleMultiAlert
        title={`${error.status} ${error.error?.code}`}
        description={error.error?.message || 'Something wrong!'}
        icon={<AiFillExclamationCircle />}
        iconColor="text-red-500"
        closeBg="hover:bg-white/20 text-navy-700 dark:text-white"
        bg="bg-[#FDE0D0] dark:!bg-navy-700"
        onClose={() => setError('')}
      />
    </div>
  );
}
