import React, { createContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useReferencesFetch from 'restApi/references';
export const ReferencesContext = createContext();

function ReferencesProvider(props) {
  const { t, i18n } = useTranslation();
  const { apiData: countries, fetchData: fetchCountry } = useReferencesFetch();
  const { apiData: prefectures, fetchData: fetchPrefecture } =
    useReferencesFetch();
  const { apiData: cities, fetchData: fetchCity } = useReferencesFetch();

  useEffect(() => {
    fetchCountry(
      `${process.env.REACT_APP_API_URL}/references/countries?languageId=${i18n.language}`
    );
    fetchPrefecture(
      `${process.env.REACT_APP_API_URL}/references/prefectures?languageId=${i18n.language}`
      // `${process.env.REACT_APP_API_URL}/references/prefectures?countryId=${value}&languageId=${i18n.language}`
    );
    fetchCity(
      `${process.env.REACT_APP_API_URL}/references/cities?languageId=${i18n.language}`
      // `${process.env.REACT_APP_API_URL}/references/cities?countryId=${countryId}&prefectureId=${value}&languageId=${i18n.language}`
    );
  }, [i18n.language]);

  return (
    <ReferencesContext.Provider
      value={{
        countries,
        prefectures,
        cities,
      }}
    >
      {props.children}
    </ReferencesContext.Provider>
  );
}

const useReferences = () => React.useContext(ReferencesContext);

export { ReferencesProvider, useReferences };
