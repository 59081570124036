import React from 'react';
import Dropdown from 'components/dropdown';
import { FiAlignJustify } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import navbarimage from 'assets/img/layout/Navbar.png';
import { BsArrowBarUp } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import Configurator from './Configurator';
// import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from 'react-icons/io';
import avatar from 'assets/img/avatars/avatar4.png';
import mongolia from 'assets/img/flags/mongolia.png';
import english from 'assets/img/flags/united-kingdom.png';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { IoLanguage } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'providers/auth';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  [x: string]: any;
}) => {
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains('dark')
  );
  const { t, i18n } = useTranslation();

  const { me } = useAuth();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav
      className={`duration-175 linear fixed top-3 right-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : mini === true && hovered === true
          ? 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]'
          : 'w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]'
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] xl:top-[20px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            {t('pages')}
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {' '}
              /{' '}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {t(brandText)}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {t(brandText)}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[145px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 ps-5 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[155px] md:flex-grow-0 md:gap-1 xl:w-[155px] xl:gap-2">
        {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div> */}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
              localStorage.setItem('mz-theme', '');
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
              localStorage.setItem('mz-theme', 'dark');
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* <Configurator
          mini={props.mini}
          setMini={props.setMini}
          theme={props.theme}
          setTheme={props.setTheme}
          darkmode={darkmode}
          setDarkmode={setDarkmode}
        /> */}
        {/* language */}

        <Dropdown
          button={
            <p className="cursor-pointer">
              {i18n.language === 'mn' ? (
                <img
                  className="h-4 w-4 rounded-full"
                  src={mongolia}
                  alt="Монгол"
                />
              ) : i18n.language === 'en' ? (
                <img
                  className="h-4 w-4 rounded-full"
                  src={english}
                  alt="Монгол"
                />
              ) : (
                <IoLanguage className="h-4 w-4 text-gray-600 dark:text-white" />
              )}
            </p>
          }
          children={
            <div className="flex h-max w-36 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href="#_"
                  onClick={() => changeLanguage('mn')}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white flex items-center"
                >
                  <img
                    className="h-5 w-5 rounded-full mr-2"
                    src={mongolia}
                    alt="Монгол"
                  />
                  Монгол
                </a>

                <a
                  href="#_"
                  onClick={() => changeLanguage('en')}
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white flex items-center"
                >
                  <img
                    className="h-5 w-5 rounded-full mr-2"
                    src={english}
                    alt="Монгол"
                  />
                  English
                </a>
              </div>
            </div>
          }
          classNames={'py-2 top-4 -left-[110px] w-max'}
          // classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
        />

        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove('dark');
              setDarkmode(false);
            } else {
              document.body.classList.add('dark');
              setDarkmode(true);
            }
          }}
        ></div>

        {/* Profile & Dropdown */}
        <Dropdown
          button={
            me.avatar ? (
              <img
                className="h-10 w-10 rounded-full"
                src={me.avatar}
                alt="Elon Musk"
              />
            ) : (
              <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <svg
                  className="absolute w-12 h-12 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            )
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    {me.firstname ||
                      me.middleName ||
                      me.email ||
                      me.lastName ||
                      'Unnamed'}
                  </p>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <Link
                  to={`/profile`}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  {t('nav.profile')}
                </Link>

                <a
                  href=" "
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                >
                   {t('nav.logout')}
                </a>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
