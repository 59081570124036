import React from 'react';
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { Portal } from '@chakra-ui/portal';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import Footer from 'components/footer/Footer';
import navRoutes from 'routes/navRoutes';
import { useCustomTheme } from 'providers/theme';
import ServerError from 'components/alert/ServerError';

export default function Admin(props: { [x: string]: any }) {
  const { setMini, mini, theme, setTheme } = useCustomTheme();

  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [hovered, setHovered] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState('');
  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(navRoutes);
    // eslint-disable-next-line
  }, [location.pathname]);
  // functions for changing the states from components
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
  
        // console.log(window.location.href.indexOf(routes[i].layout));
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = 'ltr';
  return (
    <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
      <Sidebar
        open={open}
        hovered={hovered}
        setHovered={setHovered}
        mini={mini}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full font-dm dark:bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-2.5 flex-none transition-all dark:bg-navy-900 md:pr-2 ${
            mini === false
              ? 'xl:ml-[313px]'
              : mini === true && hovered === true
              ? 'xl:ml-[313px]'
              : 'ml-0 xl:ml-[142px]'
          } `}
        >
          {/* Routes */}
          <div>
            <Portal>
              <Navbar
                onOpenSidenav={() => setOpen(!open)}
                brandText={currentRoute}
                secondary={getActiveNavbar(navRoutes)}
                theme={theme}
                setTheme={setTheme}
                hovered={hovered}
                mini={mini}
                setMini={setMini}
                {...rest}
              />
            </Portal>
            <div className="mx-auto min-h-screen p-2 !pt-[100px] md:p-2">
              <ServerError />
              <Outlet />
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
