import { z } from "zod";

import {
  PrefectureModel,
  CityModel,
  CountryModel,
  paginationQuery,
  DictionaryModel,
} from "@/models";
import { CompanyModel } from "@/models/company";

export const CompanyExtended = CompanyModel.pick({
  companyId: true,
  name: true,
  personInCharge: true,
  zipCode: true,
  addressDetails: true,
  phoneNumber: true,
  homePage: true,
  features: true,
}).extend({
  city: CityModel.pick({
    cityId: true,
    name: true,
  })
    .extend({
      dictionaries: DictionaryModel.pick({
        dictionaryId: true,
        languageId: true,
        value: true,
      })
        .array()
        .nullable(),
    })
    .nullable(),
  prefecture: PrefectureModel.pick({
    prefectureId: true,
    name: true,
  })
    .extend({
      dictionaries: DictionaryModel.pick({
        dictionaryId: true,
        languageId: true,
        value: true,
      })
        .array()
        .nullable(),
    })
    .nullable(),
  country: CountryModel.pick({
    countryId: true,
    name: true,
  })
    .extend({
      dictionaries: DictionaryModel.pick({
        dictionaryId: true,
        languageId: true,
        value: true,
      })
        .array()
        .nullable(),
    })
    .nullable(),
  info: z.object({
    deviceCount: z.string().or(z.number()),
    serviceCount: z.string().or(z.number()),
    money: z.string().or(z.number()),
  }).nullable()
});
export type CompanyExtended = z.infer<typeof CompanyExtended>;

export const CompanyReference = CompanyModel.pick({
  id: true,
  name: true,
});
export type CompanyReference = z.infer<typeof CompanyReference>;

export const CompanyCreate = CompanyModel.omit({
  companyId: true,
  createdAt: true,
  updatedAt: true,
});
export type CompanyCreate = z.infer<typeof CompanyCreate>;

export const CompanyUpdate = CompanyModel.pick({
  name: true,
  personInCharge: true,
  zipCode: true,
  prefectureId: true,
  cityId: true,
  addressDetails: true,
  phoneNumber: true,
  homePage: true,
  features: true,
});
export type CompanyUpdate = z.infer<typeof CompanyUpdate>;

export const CompaniesQuery = z
  .object({
    keywords: z.string().array(),
    name: z.string(),
    personInCharge: z.string(),
    zipCode: z.string(),
    prefectureId: z.string().array(),
    cityId: z.string().array(),
    addressDetails: z.string(),
    phoneNumber: z.string(),
    homePage: z.string(),
    features: z.string(),
  })
  .merge(paginationQuery(z.enum(["updatedAt"])))
  .partial()
  .optional();
export type CompaniesQuery = z.infer<typeof CompaniesQuery>;


export const CompanySuspend =z.object({
  statusId: z.number(),
  note: z.string().optional()
});

export type CompanySuspend = z.infer<typeof CompanySuspend>;

export const CompanyAddDevice = z.object({
  deviceId: z.number(),
  serviceAt: z.coerce.date(),
});

export type CompanyAddDevice = z.infer<typeof CompanyAddDevice>;
