import { pgTable, text, timestamp, serial, integer } from "drizzle-orm/pg-core";
import { admins } from "@/schemas/admins";
import { relations } from "drizzle-orm";
import { device_batteries } from "./device_batteries";
import { company_devices } from "@/schemas/companies/company_devices";

export const devices = pgTable("devices", {
  deviceId: serial("device_id").primaryKey(),
  name: text("name").notNull(),
  serialId: text("serial_id").notNull(),
  macId: text("mac_address"),
  ipAddress: text("ip_address"),
  simId: text("sim_id"),
  phoneNumber: text("phone_number"),
  createdBy: integer("created_by")
    .references(() => admins.adminId),
  updatedBy: integer("updated_by")
    .references(() => admins.adminId),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const devices_relations = relations(devices, ({ one }) => ({
  battery: one(device_batteries, {
    fields: [devices.deviceId],
    references: [device_batteries.deviceId],
  }),
  companyDevice: one(company_devices),
}));
