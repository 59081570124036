import { devices, device_batteries, device_battery_histories } from "database";

import { createSelectSchema } from "drizzle-zod";
import { z } from "zod";

export const DeviceModel = createSelectSchema(devices);
export type DeviceModel = z.infer<typeof DeviceModel>;

export const DeviceBatteryModel = createSelectSchema(device_batteries);
export type DeviceBatteryModel = z.infer<typeof DeviceBatteryModel>;

export const DeviceBatteryHistoryModel = createSelectSchema(device_battery_histories);
export type DeviceBatteryHistoryModel = z.infer<typeof DeviceBatteryHistoryModel>;
