import { z } from "zod";

import { c } from "@/contract";
import { PaginationMeta } from "@/models";
import { CustomError } from "@/models/custom_error";
import { BatteriesQuery, BatteryExtended, BatteryCreate, BatteryUpdate } from "../schemas/dtos/battery";

export const batteryContract = c.router({
  getBatteries: {
    method: "GET",
    path: "/batteries",
    query: BatteriesQuery,
    responses: {
      200: z.object({
        data: BatteryExtended.array(),
        meta: PaginationMeta,
      }),
      400: CustomError,
    },
    summary: "search device with filter",
  },
  getBattery: {
    method: "GET",
    path: "/batteries/:id",
    responses: {
      200: BatteryExtended,
      400: CustomError,
    },
    summary: "get device detail by id",
  },
  updateBattery: {
    method: "PATCH",
    path: "/batteries/:id",
    body: BatteryUpdate,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "update battery",
  },
  createBattery: {
    method: "POST",
    path: "/batteries",
    body: BatteryCreate,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "create battery",
  },
});
