import {
  AnyPgColumn,
  integer,
  pgTable,
  serial,
  text,
} from "drizzle-orm/pg-core";
import { dictionary_entries } from "@/schemas/dictionary_entries";
import { relations } from "drizzle-orm";
import { prefectures } from "./prefectures";
import { users } from "@/schemas/users";
import { companies } from "@/schemas/companies";

export const countries = pgTable("countries", {
  countryId: serial("country_id").primaryKey(),
  parentId: integer("parent_id").references(
    (): AnyPgColumn => countries.countryId
  ),
  code: text("code"),
  name: text("name").notNull(),
  dictionaryId: integer("dictionary_id")
    .notNull().unique()
});

export const countries_relations = relations(countries, ({ many, one }) => ({
  parent: one(countries, {
    fields: [countries.countryId],
    references: [countries.parentId]
  }),
  prefectures: many(prefectures),
  dictionaries: many(dictionary_entries),
  users: many(users),
  companies: many(companies),
}));