import React from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AdminLayout from '../layouts/admin';
import AuthLayout from '../layouts/auth';
import SkeletonLoaderPageList from 'components/loader/SkeletonLoaderPageList';
import { GlobalProviders, ProtectedProviders } from 'providers';

const Home = React.lazy(() => import('views/admin/home'));
// company
const Companies = React.lazy(() => import('views/admin/company/list'));
const CompanyNew = React.lazy(() => import('views/admin/company/new'));
const CompanyEdit = React.lazy(() => import('views/admin/company/edit'));
const CompaniesProvider = React.lazy(() =>
  import('views/admin/company/list/context')
);
const CompanyDetailProvider = React.lazy(() =>
  import('views/admin/company/edit/context')
);
// battery
const Batteries = React.lazy(() => import('views/admin/battery/list'));
const BatteryNew = React.lazy(() => import('views/admin/battery/new'));
const BatteryEdit = React.lazy(() => import('views/admin/battery/edit'));
const BatteriesProvider = React.lazy(() =>
  import('views/admin/battery/list/context')
);
const BatteryDetailProvider = React.lazy(() =>
  import('views/admin/battery/edit/context')
);
// device
const Devices = React.lazy(() => import('views/admin/device/list'));
const DeviceNew = React.lazy(() => import('views/admin/device/new'));
const DeviceEdit = React.lazy(() => import('views/admin/device/edit'));
const DevicesProvider = React.lazy(() =>
  import('views/admin/device/list/context')
);
const DeviceDetailProvider = React.lazy(() =>
  import('views/admin/device/edit/context')
);
// customer
const Customers = React.lazy(() => import('views/admin/customer/list'));
const CustomerNew = React.lazy(() => import('views/admin/customer/new'));
const CustomerEdit = React.lazy(() => import('views/admin/customer/edit'));
const CustomersProvider = React.lazy(() =>
  import('views/admin/customer/list/context')
);

const TransactionReport = React.lazy(() =>
  import('views/admin/reports/transaction')
);
const WorkedReport = React.lazy(() => import('views/admin/reports/worked'));
const OtherReport = React.lazy(() => import('views/admin/reports/other'));
// staff
const Staffs = React.lazy(() => import('views/admin/settings/staff/list'));
const StaffNew = React.lazy(() => import('views/admin/settings/staff/new'));
const StaffEdit = React.lazy(() => import('views/admin/settings/staff/edit'));
const StaffsProvider = React.lazy(() =>
  import('views/admin/settings/staff/list/context')
);
// profile
const Profile = React.lazy(() => import('views/admin/profile'));
// auth
const SignIn = React.lazy(() => import('views/auth/signIn/SignIn'));

export default createBrowserRouter([
  {
    element: (
      <GlobalProviders>
        <Outlet />
      </GlobalProviders>
    ),
    children: [
      {
        path: '/',
        element: (
          <ProtectedProviders>
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          </ProtectedProviders>
        ),
        children: [
          {
            path: '/home',
            element: (
              <React.Suspense fallback={<SkeletonLoaderPageList />}>
                <Home />
              </React.Suspense>
            ),
          },
          {
            path: '/company',
            children: [
              {
                path: '/company/list',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CompaniesProvider>
                      <Companies />
                    </CompaniesProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/company/list/:id',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CompanyDetailProvider>
                      <CompanyEdit />
                    </CompanyDetailProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/company/new',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CompanyNew />
                  </React.Suspense>
                ),
              },
              {
                path: '/company',
                element: <Navigate to={'/company/list'} replace />,
              },
            ],
          },
          {
            path: '/device',
            children: [
              {
                path: '/device/list',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <DevicesProvider>
                      <Devices />
                    </DevicesProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/device/list/:id',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <DeviceDetailProvider>
                      <DeviceEdit />
                    </DeviceDetailProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/device/new',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <DeviceNew />
                  </React.Suspense>
                ),
              },
              {
                path: '/device',
                element: <Navigate to={'/device/list'} replace />,
              },
            ],
          },
          {
            path: '/battery',
            children: [
              {
                path: '/battery/list',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <BatteriesProvider>
                      <Batteries />
                    </BatteriesProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/battery/list/:id',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <BatteryDetailProvider>
                      <BatteryEdit />
                    </BatteryDetailProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/battery/new',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <BatteryNew />
                  </React.Suspense>
                ),
              },
              {
                path: '/battery',
                element: <Navigate to={'/battery/list'} replace />,
              },
            ],
          },
          {
            path: '/customer',
            children: [
              {
                path: '/customer/list',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CustomersProvider>
                      <Customers />
                    </CustomersProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/customer/list/:id',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CustomerEdit />
                  </React.Suspense>
                ),
              },
              {
                path: '/customer/new',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <CustomerNew />
                  </React.Suspense>
                ),
              },
              {
                path: '/customer',
                element: <Navigate to={'/customer/list'} replace />,
              },
            ],
          },

          {
            path: '/reports/transaction',
            element: (
              <React.Suspense fallback={<SkeletonLoaderPageList />}>
                <TransactionReport />
              </React.Suspense>
            ),
          },
          {
            path: '/reports/worked',
            element: (
              <React.Suspense fallback={<SkeletonLoaderPageList />}>
                <WorkedReport />
              </React.Suspense>
            ),
          },
          {
            path: '/reports/other',
            element: (
              <React.Suspense fallback={<SkeletonLoaderPageList />}>
                <OtherReport />
              </React.Suspense>
            ),
          },
          {
            path: '/settings/staff',
            children: [
              {
                path: '/settings/staff/list',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <StaffsProvider>
                      <Staffs />
                    </StaffsProvider>
                  </React.Suspense>
                ),
              },
              {
                path: '/settings/staff/list/:id',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <StaffEdit />
                  </React.Suspense>
                ),
              },
              {
                path: '/settings/staff/new',
                element: (
                  <React.Suspense fallback={<SkeletonLoaderPageList />}>
                    <StaffNew />
                  </React.Suspense>
                ),
              },
              {
                path: '/settings/staff',
                element: <Navigate to={'/settings/staff/list'} replace />,
              },
            ],
          },
          {
            path: '/profile',
            element: (
              <React.Suspense fallback={<SkeletonLoaderPageList />}>
                <Profile />
              </React.Suspense>
            ),
          },
          {
            path: '/',
            element: <Navigate to={'/home'} replace />,
          },
        ],
      },
      {
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            path: '/auth/sign-in',
            element: (
              <React.Suspense fallback={'Loading...'}>
                <SignIn />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
]);
