import { relations } from "drizzle-orm";
import { boolean, integer, pgTable, serial, text, timestamp, uuid } from "drizzle-orm/pg-core";

import { account_status } from "@/enums/account_status";
import { countries } from "@/references/countries";
import { companies } from "@/schemas/companies";

export const admins = pgTable("admins", {
  adminId: serial("admin_id").primaryKey(),
  email: text("email").notNull().unique(),
  hash: text("hash").notNull(),
  isEmailVerified: boolean("is_email_verified").notNull().default(false),
  status: account_status("status").notNull().default("pending_verify"),
  firstName: text("first_name").notNull(),
  middleName: text("middle_name"),
  lastName: text("last_name"),
  phoneNumber: text("phone_number"),
  countryId: integer("country_id").references(() => countries.countryId),
  companyId: integer("company_id")
      .notNull()
      .references(() => companies.companyId, { onDelete: "cascade" }),
  lastLoggedInAt: timestamp("last_logged_in_at"),
  loggedInCount: integer("logged_in_count").notNull().default(0),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
  deletedAt: timestamp("deleted_at"),
});

export const admins_relations = relations(admins, ({ one }) => ({
  company: one(companies, {
    fields: [admins.companyId],
    references: [companies.companyId],
  }),
  country: one(countries, {
    fields: [admins.countryId],
    references: [countries.countryId],
  }),
}));