import { z } from "zod";

export const paginationQuery = <T>(sortingField: z.ZodType<T>) =>
  z.object({
    currentPage: z.coerce.number().optional(),
    perPage: z.coerce.number().optional(),
    sortingField,
    sortingOrder: z.enum(["ascending", "descending"]),
  });
export type PaginationQuery<T> = z.infer<ReturnType<typeof paginationQuery<T>>>;

export const PaginationMeta = z.object({
  currentPage: z.coerce.number(),
  nextPage: z.coerce.number().nullable(),
  perPage: z.coerce.number(),
  previousPage: z.coerce.number().nullable(),
  total: z.coerce.number(),
  totalPage: z.coerce.number(),
});
export type PaginationMeta = z.infer<typeof PaginationMeta>;

export const CursorPaginationQuery = z.object({
  nextCursor: z.coerce.number().optional(),
  perPage: z.coerce.number().optional(),
});
export type CursorPaginationQuery = z.infer<typeof CursorPaginationQuery>;

export const CursorPaginationMeta = z.object({
  nextCursor: z.coerce.number().nullable(),
  perPage: z.number(),
});
export type CursorPaginationMeta = z.infer<typeof CursorPaginationMeta>;
