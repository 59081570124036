import { relations } from "drizzle-orm";
import {
  boolean,
  integer,
  pgTable,
  serial,
  text,
  unique,
} from "drizzle-orm/pg-core";
import { cities } from "@/references/cities";
import { countries } from "@/references/countries";
import { prefectures } from "@/references/prefectures";

export const dictionary_entries = pgTable("dictionary_entry", {
  Id: serial("id").primaryKey(),
  dictionaryId: integer("dictionary_id").notNull().unique(),
  languageId: text("language_id").notNull(),
  value: text("value").notNull(),
});

export const dictionary_entries_relations = relations(
  dictionary_entries,
  ({ one }) => ({
    prefecture: one(prefectures, {
      fields: [dictionary_entries.dictionaryId],
      references: [prefectures.dictionaryId],
    }),
    city: one(cities, {
      fields: [dictionary_entries.dictionaryId],
      references: [cities.dictionaryId],
    }),
    country: one(countries, {
      fields: [dictionary_entries.dictionaryId],
      references: [countries.dictionaryId],
    }),
  })
);
