import { z } from "zod";

import {
  AdminAccountsStatusUpdate,
  AdminAccountExtended,
  AdminAccountQuery,
  AdminInvite,
} from "@/admin/schemas/dtos";
import { c } from "@/contract";
import { CustomError } from "@/models/custom_error";
import { PaginationMeta } from "@/models/pagination";

export const accountContract = c.router({
  getAccounts: {
    method: "GET",
    path: "/accounts",
    query: AdminAccountQuery,
    responses: {
      200: z.object({
        data: AdminAccountExtended.array(),
        meta: PaginationMeta,
      }),
      400: CustomError,
    },
    summary: "",
  },
  inviteAccount: {
    method: "POST",
    path: "/accounts/invite_account",
    body: AdminInvite,
    responses: {
      201: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  excludeAccount: {
    method: "DELETE",
    path: "/accounts/:id/exclude",
    body: z.object({}),
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  updateAccountsStatus: {
    method: "POST",
    path: "/accounts/status",
    body: AdminAccountsStatusUpdate,
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
  resendInvitationAccount: {
    method: "PATCH",
    path: "/accounts/:id/resendInvitation",
    body: z.object({}),
    responses: {
      200: z.object({}),
      400: CustomError,
    },
    summary: "",
  },
});
