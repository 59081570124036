import { relations } from "drizzle-orm";
import { integer, pgTable, serial, timestamp, numeric } from "drizzle-orm/pg-core";
import { admins } from "@/schemas/admins";
import { devices } from "@/schemas/devices";
import { companies } from ".";

export const company_devices = pgTable("company_devices", {
  Id: serial("id").primaryKey(),
  companyId: integer("company_id")
    .notNull()
    .references(() => companies.companyId),
  deviceId: integer("device_id")
    .notNull()
    .unique()
    .references(() => devices.deviceId),
  productedAt: timestamp("producted_at"),
  createdBy: integer("created_by")
    .notNull()
    .references(() => admins.adminId),
  updatedBy: integer("updated_by")
    .notNull()
    .references(() => admins.adminId),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const company_devices_relations = relations(company_devices, ({ one, many }) => ({
  companies: one(companies, {
    fields: [company_devices.companyId],
    references: [companies.companyId],
  }),
}));
