import { relations } from "drizzle-orm";
import { pgTable, text, timestamp, serial, integer, smallint, real, AnyPgColumn } from "drizzle-orm/pg-core";
import { prefectures } from "@/references/prefectures";
import { cities } from "@/references/cities";
import { countries } from "@/references/countries";
import { admins } from "@/schemas/admins";
import { company_devices } from "./company_devices";


export const companies = pgTable("companies", {
  companyId: serial("company_id").primaryKey(),
  parentId: integer("parent_id").references((): AnyPgColumn => companies.companyId, {
    onDelete: "cascade",
  }),
  name: text("name").notNull(),
  personInCharge: text("person_in_charge").notNull(),
  phoneNumber: text("phone_number"),
  addressDetails: text("address_details"),
  zipCode: text("zip_code"),
  registerId: text("register_number"),
  homePage: text("home_page"),
  features: text("features"),
  latitude: real("latitude"),
  longitude: real("longitude"),
  prefectureId: integer("prefecture_id").references(() => prefectures.prefectureId),
  cityId: integer("city_id").references(() => cities.cityId),
  countryId: integer("country_id").references(() => countries.countryId),
  statusId: smallint("status_id"),
  imageId: text("image_id"),
  note: text("note"),
  updatedBy: integer("updated_by"),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const companies_relations = relations(companies, ({ many, one }) => ({
  adminCompanies: many(admins),
  country: one(countries, {
    fields: [companies.countryId],
    references: [countries.countryId],
  }),
  city: one(cities, {
    fields: [companies.cityId],
    references: [cities.cityId],
  }),
  prefecture: one(prefectures, {
    fields: [companies.prefectureId],
    references: [prefectures.prefectureId],
  }),
  devices: many(company_devices)
}));

