import React, { useEffect } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
import { useAuth } from 'providers/auth';
import { useNavigate } from 'react-router-dom';

export default function Auth() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <FixedPlugin />
          <Outlet />
        </main>
      </div>
    </div>
  );
}
