import { pgTable, integer, serial, timestamp, text } from "drizzle-orm/pg-core";
import { admins } from "@/schemas/admins";
import { relations } from "drizzle-orm";
import { device_batteries } from "@/schemas/devices/device_batteries";

export const batteries = pgTable("batteries", {
  batteryId: serial("battery_id").primaryKey(),
  serialId: text("serial_id"),
  name: text("name"),
  createdBy: integer("created_by").references(() => admins.adminId),
  updatedBy: integer("updated_by").references(() => admins.adminId),
  createdAt: timestamp("created_at").notNull().defaultNow(),
  updatedAt: timestamp("updated_at").notNull().defaultNow(),
});

export const batteries_releations = relations(batteries, ({ one }) => ({
  deviceBattery: one(device_batteries, {
    fields: [batteries.batteryId],
    references: [device_batteries.batteryId],
  }),
}));
